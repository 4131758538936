import domReady from '@wordpress/dom-ready';

domReady(() => {
  const areasWrappers = document.querySelectorAll('.members-and-areas__areas');
  const teamWrappers = document.querySelectorAll('.members-and-areas__members');

  if (0 < areasWrappers.length) {
    areasWrappers.forEach((areasWrapper) => {
      const areasListing = areasWrapper.querySelector(
        '.members-and-areas__areas__listing'
      );
      const areasItems = areasListing.querySelectorAll('.area-item');
      const areasLoadMore = areasWrapper.querySelector('.load-more');

      let areaIndexMin = 8;
      let areaIndexMax = 16;

      if (areasLoadMore) {
        areasLoadMore.addEventListener('click', () => {
          const range = `.area-item:nth-child(n+${areaIndexMin}):nth-child(-n+${areaIndexMax})`;
          areasListing
            .querySelectorAll(range)
            .forEach((elem) => elem.classList.remove('hidden'));
          if (areasItems.length <= areaIndexMax) {
            areasLoadMore.remove();
          } else {
            areaIndexMin += 5;
            areaIndexMax += 5;
          }
        });
      }
    });
  }

  if (0 < teamWrappers.length) {
    teamWrappers.forEach((teamWrapper) => {
      const teamListing = teamWrapper.querySelector(
        '.members-and-areas__members__listing'
      );
      const teamItems = teamListing.querySelectorAll('.team-item');
      const teamLoadMore = teamWrapper.querySelector('.load-more');

      let teamIndexMin = 8;
      let teamIndexMax = 16;

      if (teamLoadMore) {
        teamLoadMore.addEventListener('click', () => {
          const range = `.team-item:nth-child(n+${teamIndexMin}):nth-child(-n+${teamIndexMax})`;
          teamListing
            .querySelectorAll(range)
            .forEach((elem) => elem.classList.remove('hidden'));
          if (teamItems.length <= teamIndexMax) {
            teamLoadMore.remove();
          } else {
            teamIndexMin += 5;
            teamIndexMax += 5;
          }
        });
      }
    });
  }
});
